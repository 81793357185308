import React, { useRef } from 'react';
import { auth } from '../firebaseConfig';
import './SignUpScreen.css'

function SignInScreen() {

  const emailRef =  useRef(null);
  const passwordRef =  useRef(null);


  const register = (e) => {
    e.preventDefault();
    auth.createUserWithEmailAndPassword(
      emailRef.current.value,
      passwordRef.current.value
    ).then((authUser) => {
      console.log(authUser);
    }).catch((error) => {
        alert(error.message);
    });
  }

  const signIn = (e) => {
    e.preventDefault();

    auth.signInWithEmailAndPassword(
      emailRef.current.value,
      passwordRef.current.value
    ).then((authUser) => {
      console.log(authUser);
    }).catch((error) => {
        alert(error.message);
    });
  }

  return (
    <div className="signupScreen">
        <form>
          <h1>Sign In</h1>
          <input ref={emailRef} type="email" placeholder="Enter your email" />
          <input ref={passwordRef} type="password" placeholder="Password" />
          <button onClick={signIn}>Sign In</button>

          <h4>
            <span className="signupScreen__gray">New to netflix ? </span>
            <span className="signupScreen__link" onClick={register}>Sign Up now.</span>  
          </h4>
        </form>
    </div>
  )
}

export default SignInScreen